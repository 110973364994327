import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';

export const rollbarConfig = {
  accessToken: 'e754942ce4a34e37870ebb4f0d8f871a',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV,
};

export const wrapRootElement = ({ element }) => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>{element}</ErrorBoundary>
  </Provider>
);
