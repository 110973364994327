import './src/styles/global.css';
import 'reactjs-popup/dist/index.css';
import 'react-alice-carousel/lib/alice-carousel.css';

import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { rollbarConfig } from './gatsby-ssr';

export const wrapRootElement = ({ element }) => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>{element}</ErrorBoundary>
  </Provider>
);
